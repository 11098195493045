/*
 *  Licensed under the EUPL, Version 1.2 or – as soon they will be approved by
the European Commission - subsequent versions of the EUPL (the "Licence");
You may not use this work except in compliance with the Licence.
You may obtain a copy of the Licence at:

  https://joinup.ec.europa.eu/software/page/eupl

Unless required by applicable law or agreed to in writing, software
distributed under the Licence is distributed on an "AS IS" basis,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the Licence for the specific language governing permissions and
limitations under the Licence. */

import React from "react";
import SvgIcon from "material-ui/SvgIcon";

const Heated = (props) => (
  <SvgIcon
    {...props}
    x="0px"
    y="0px"
    viewBox="0 0 272.987 272.987"
    enableBackground="new 0 0 272.987 272.987"
  >
    <g>
      <path
        d="M245.083,87.639c-3.958,0-7.169,3.213-7.169,7.168v28.035h-8.013v-6.389c0-15.887-12.928-28.814-28.811-28.814
			c-8.629,0-16.357,3.834-21.646,9.859c-5.288-6.025-13.017-9.859-21.642-9.859c-8.629,0-16.362,3.834-21.646,9.859
			c-5.288-6.025-13.019-9.859-21.646-9.859c-8.625,0-16.358,3.834-21.644,9.859c-5.286-6.025-13.019-9.859-21.646-9.859
			c-15.885,0-28.812,12.928-28.812,28.814v6.389H27.919c-3.955,0-7.168,3.211-7.168,7.168c0,3.956,3.213,7.169,7.168,7.169h14.478
			v106.993c0,15.887,12.928,28.814,28.812,28.814c8.627,0,16.36-3.832,21.646-9.857c5.286,6.025,13.017,9.857,21.644,9.857
			s16.358-3.832,21.644-9.857c5.285,6.025,13.019,9.857,21.644,9.857s16.362-3.832,21.646-9.857
			c5.283,6.025,13.021,9.857,21.646,9.857c15.887,0,28.814-12.928,28.814-28.814V137.184h15.178c3.957,0,7.168-3.213,7.168-7.168
			V94.812C252.252,90.852,249.036,87.639,245.083,87.639z M215.563,137.184v106.993c0,7.985-6.492,14.478-14.473,14.478
			s-14.477-6.492-14.477-14.478V137.184v-14.337v-6.389c0-7.983,6.496-14.478,14.477-14.478s14.473,6.494,14.473,14.478v6.389
			V137.184z M172.271,244.173c0,7.984-6.496,14.477-14.477,14.477c-7.981,0-14.478-6.492-14.478-14.477V137.184v-14.337v-6.389
			c0-7.983,6.496-14.478,14.478-14.478c7.98,0,14.477,6.494,14.477,14.478v6.389v14.337V244.173z M128.982,244.173
			c0,7.984-6.494,14.477-14.475,14.477c-7.98,0-14.475-6.492-14.475-14.477V137.184v-14.337v-6.389
			c0-7.983,6.495-14.478,14.475-14.478c7.981,0,14.475,6.494,14.475,14.478v6.389v14.337V244.173z M85.69,244.173
			c0,7.984-6.497,14.477-14.477,14.477c-7.981,0-14.475-6.492-14.475-14.477V137.184v-14.337v-6.389
			c0-7.983,6.494-14.478,14.475-14.478c7.98,0,14.477,6.494,14.477,14.478v6.389v14.337V244.173z"
      />
      <path
        d="M88.472,2.404c-6.461,7.708-9.12,15.87-5.918,25.669c3.16,9.667,12.865,18.533,5.12,28.493
			c-5.603,7.201,4.467,17.429,10.135,10.137c6.38-8.203,9.138-16.57,5.918-26.75c-2.905-9.185-12.846-18.197-5.12-27.412
			C104.506,5.505,94.42-4.692,88.472,2.404z"
      />
      <path
        d="M140.825,66.702c6.385-8.203,9.138-16.57,5.918-26.75c-2.903-9.185-12.847-18.197-5.12-27.412
			c5.899-7.036-4.191-17.233-10.135-10.137c-6.461,7.708-9.119,15.87-5.917,25.669c3.16,9.667,12.865,18.533,5.12,28.493
			C125.083,63.767,135.152,73.994,140.825,66.702z"
      />
      <path
        d="M174.493,2.404c-6.459,7.708-9.119,15.87-5.918,25.669c3.164,9.667,12.867,18.533,5.125,28.493
			c-5.605,7.201,4.462,17.429,10.132,10.137c6.38-8.203,9.139-16.57,5.918-26.75c-2.907-9.185-12.849-18.197-5.12-27.412
			C190.529,5.505,180.444-4.692,174.493,2.404z"
      />
    </g>
  </SvgIcon>
);
export default Heated;
